// Librerias o npm
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';

import Profile from './Profile';

import aboutUsLegalbf from '../../assets/images/aboutUsLegalbf.jpg';
import vision from '../../assets/images/mision.jpg';
import mision from '../../assets/images/Vision.jpg';
import ceo from '../../assets/images/Ceo-Legalbf.jpeg';
import director from '../../assets/images/Director-legalbf.jpeg';
// import listIcon from '../../assets/images/listIcon.png';

import './AboutUs.css';

const AboutUs = () => {

    let topRef = useRef(null);

    const infoTeam = [{
        id: 1,
        name: 'Carlos F. Ojeda M.',
        positionWorker: 'Fundador y CEO de Legal Business s.a.s.',
        urlImg: ceo,
        description: 'Con mas de 15 años de  experiencia en el área administrativa y financiera en diferentes Entidades del Estado, al haber ejercido funciones  como Jefe de Presupuesto, Tesorero y Director financiero así como Consultor del Programa de las Naciones Unidas apoyando la implementación del Presupuesto Orientado a Resultados en las Empresas Sociales del Estado. En el sector Privado asesora actualmente a la Organización Jurídica James Hurtado López, en temas relacionados con el Derecho Administrativo y la Negociación de Sentencias buscando el mayor beneficio a los beneficiarios de las mismas.',
        email: 'carlosojeda@legalbf.com',
        linkedin: 'https://www.linkedin.com/in/carlos-fernando-ojeda-moreno-b8900b11/'
    },
    {
        id: 2,
        name: 'Luisa F. Ojeda R.',
        positionWorker: 'Directora en Legal Business s.a.s',
        urlImg: director,
        description: 'MBA de la Universidad de los Andes,Profesional en Finanzas y Comercio Internacional, 7 años de experiencia en el mercado de gestión de derechos económicos de sentencias en contra del Estado.',
        email: 'luisaojeda@legalbf.com',
        linkedin: 'https://www.linkedin.com/in/luisa-fernanda-ojeda-roa-826074120/'
    }];

    const [stateInfo, setStateInfo] = useState(false);
    const [showInfoTeam, setShowInfoTeam] = useState();

    const showInfoWorker = (value) => {
        setStateInfo(true);
        setShowInfoTeam(infoTeam.filter((data) => data.id === value));
    }

    return (
        <div>
            <h2 className="titleAboutUs">¿Quienes Somos?</h2>
            <hr className="lineSpace" />

            <article className="sectionInformativeLegalbf">
                <p><span>Legal Business S.A.S</span> es una de las empresas pioneras
                en el mercado de compra de derechos económicos derivados de sentencias y conciliaciones
                judiciales con fallo ejecutoriado y en firme en contra del Estado,  con mas de  trece años
                de experiencia ofreciendo servicios financieros y jurídicos, trabajando con el objetivo de lograr
                grandes negocios e inversiones con calidad y eficiencia, brindando así de manera más oportuna soluciones 
                financieras efectivas. 
                </p>
                <img src={aboutUsLegalbf} alt="LegalBf About Us img" />
            </article>

            <Link href='#top-menu' to='/contacto' onClick={() => { topRef.current.scrollIntoView(true) }}>
                <button className="btnContactAbout">Contactanos<span className="icon-right-hand"></span></button>
            </Link>

            <section className="container-front">
                <div className="cards">
                    <img src={mision} alt="LegalBf Mision" />
                    <div className="infoCard">
                        <h3>Misión</h3>
                        <p>Prestar servicios de gestión jurídica y financiera en la compra de derechos económicos derivados de sentencias y conciliaciones judiciales falladas en contra de entidades del Estado.</p>
                    </div>
                </div>

                <div className="cards">
                    <img src={vision} alt="LegalBf Vision" />
                    <div className="infoCard">
                        <h3>Vision</h3>
                        <p>Realizarnos como una firma integral y sostenible, reconocida a nivel nacional e internacional, por la calidad de los servicios desde nuestro talento humano hacia nuestros clientes.</p>
                    </div>
                </div>
            </section>

            <h2 className="titleAboutUs">Equipo Directivo</h2>

            <article className="sectionTeamLegalbf">
                <Zoom top>
                    <section className="targetTeam">
                        <div className="workerImage">
                            <img src={ceo} alt="ceo imagen legal business" />
                            <p className="positionWorker">CEO y Fundador</p>
                            <hr />
                            <p className="nameWorker">Carlos F. Ojeda M.</p>
                        </div>

                        <section className="btnAboutMe">
                            <button onClick={() => showInfoWorker(1)}>Mas Sobre mi</button>
                        </section>
                    </section>
                </Zoom>

                <Zoom top>
                    <section className="targetTeam">
                        <div className="workerImage">
                            <img src={director} alt="director imagen legal business" />
                            <p className="positionWorker">Directora</p>
                            <hr />
                            <p className="nameWorker">Luisa F. Ojeda R.</p>
                        </div>

                        <section className="btnAboutMe">
                            <button onClick={() => showInfoWorker(2)}>Mas Sobre mi</button>
                        </section>
                    </section>
                </Zoom>

            </article>
                {stateInfo ? <Profile showInfoTeam={showInfoTeam} setStateInfo={setStateInfo} /> : null}
        </div>
    );
};

export default AboutUs;