import React, { useState, useEffect } from 'react';
import logolegalBf from '../../assets/images/Logo-LegalBF.webp';

const Header = () => {

    const [dayState, setDateState] = useState();
    const [dayStateBooleano, setDayStateBooleano] = useState();

    let date = new Date();

    useEffect(()=>{
        if (date.getDay() !== 0 && date.getDay() !== 6){
            if(date.getHours() >= 8 && date.getHours() < 17 ) {
                setDateState('Abierto Justo Ahora');
                setDayStateBooleano(true);
            } else{
                setDateState('Cerrado Justo Ahora');
                setDayStateBooleano(false);
            }
        } else {
            setDateState('Cerrado Justo Ahora');
            setDayStateBooleano(false);
        }
    },[])

    return (
        <div className="sectionInfoHeader">
            <section className="logoSection">
                <img src={logolegalBf} alt="logo legalBf"></img>
            </section>
            
            <section className="sectionDirection">
                <p><span className="icon-wristwatch"></span>Atencion de Lun - Vie de 08.00 am - 17.00 pm</p>
                <p>Sabados y domingos <span style={{ color: "#1625f4" }}>CERRADO</span></p>
                <p className="statePage" style={dayStateBooleano ? { color: '#12b725'} : {color: '#1625f4'}}>{dayState}</p>
                
                {/* <a href="https://goo.gl/maps/vi9UGqpkz8NStH6z8" target="_blank" rel="noreferrer">
                    <span className="icon-location"></span>calle 26 # 69 - 76 Torre 3 Of 1501 Bogotá - Colombia
                </a> */}
            </section>
        </div>
    );
};

export default Header;